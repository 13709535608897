.App {
  text-align: left;
}

.portfolio-item{
  margin-right: 20px;
}

.price-area{
  margin-top: -100px;
}

.pembayaran-area{
  margin-bottom: 50px;
}

.text-left{
  text-align: left;
}
.contact-wrap-form p {
  text-align: left;
}

.font-semibig{
  font-size: 16px;
  padding: 8px 24px;
}

.single-price:hover .ul p{
  color: white;
}

.single-price:hover h5{
  color: white;
}

.single-price .ul p{
  color: #04428d;
}

.single-price h5{
  color: #04428d;
}